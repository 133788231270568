import React from "react";
import logo from "./assets/logo.png";
import "./App.css";

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src={logo} className="App-logo" alt="logo" />
          <p className="slogan">Data-Driven Goodness</p>
        </div>
      </header>
    </div>
  );
};

export default App;
